/* To be used for all specific cases App-wide.
 * Styles should be incorporated in Components via makeStyles, material ui theme overrides
 **/

div[data-testid=location-display] {
    display: none;
}

* {
    margin: 0;
    padding: 0;
}
